import Courses from "../components/Courses";
import Classes from "../components/Classes";

const Home = () => {
  return (
    <>
      {" "}
      <section id="Home">
        <div className="pt-5">
          <div className="d-flex flex-column gap-5 justify-content-center* align-items-center*">
            <Classes />

            <Courses />
          </div>
        </div>
      </section>{" "}
    </>
  );
};

export default Home;
