import React, { useContext, useEffect, useState } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import Logo from "../img/logo.png";
import { GetClasses } from "../services/api.service";
import DispatchContext from "../DispatchContext";
import { Link } from "react-router-dom";

const HomeNav = () => {
  const dispatch = useContext(DispatchContext);
  const [courseClass, setCourseClass] = useState<string>("paid");

  useEffect(() => {
    GetClasses(dispatch, courseClass!); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseClass]);

  return (
    <>
      {" "}
      <Navbar variant="light" bg="light">
        <Container>
          <Link
            to={`/paid`}
            className="navbar-brand"
            onClick={() => {
              setCourseClass("paid");
              dispatch({ type: "COURSE", payload: null! });
            }}
          >
            <img src={Logo} alt="eCampus" height="40" />
          </Link>

          <Nav className="mx-auto d-none d-md-flex">
            <Link
              to={`/paid`}
              className="nav-link"
              style={
                courseClass === "paid"
                  ? {
                      cursor: "pointer",
                      padding: "10px 12px",
                      background: "rgba(13,12,34,0.05)",
                      fontWeight: 500,
                      color: "#0d0c22",
                      borderRadius: "8px",
                    }
                  : {
                      cursor: "pointer",
                      color: "#6e6d7a",
                      padding: "10px 12px",
                    }
              }
              onClick={() => {
                setCourseClass("paid");
                dispatch({ type: "COURSE", payload: null! });
              }}
            >
              Paid Classes
            </Link>
            <Link
              to={`/other`}
              className="nav-link"
              // class="nav-link"
              style={
                courseClass === "public"
                  ? {
                      cursor: "pointer",
                      padding: "10px 12px",
                      background: "rgba(13,12,34,0.05)",
                      fontWeight: 500,
                      color: "#0d0c22",
                      borderRadius: "8px",
                    }
                  : {
                      cursor: "pointer",
                      color: "#6e6d7a",
                      padding: "10px 12px",
                    }
              }
              onClick={() => {
                setCourseClass("public");
                dispatch({ type: "COURSE", payload: null! });
              }}
            >
              Other Classes
            </Link>

            <Link
              to={`/jhs`}
              className="nav-link"
              style={
                courseClass === "jhs"
                  ? {
                      cursor: "pointer",
                      padding: "10px 12px",
                      background: "rgba(13,12,34,0.05)",
                      fontWeight: 500,
                      color: "#0d0c22",
                      borderRadius: "8px",
                    }
                  : {
                      cursor: "pointer",
                      color: "#6e6d7a",
                      padding: "10px 12px",
                    }
              }
              onClick={() => {
                setCourseClass("jhs");
                dispatch({ type: "COURSE", payload: null! });
              }}
            >
              JHS Classes
            </Link>
            <Link
              to={`/shs`}
              className="nav-link"
              style={
                courseClass === "shs"
                  ? {
                      cursor: "pointer",
                      padding: "10px 12px",
                      background: "rgba(13,12,34,0.05)",
                      fontWeight: 500,
                      color: "#0d0c22",
                      borderRadius: "8px",
                    }
                  : {
                      cursor: "pointer",
                      color: "#6e6d7a",
                      padding: "10px 12px",
                    }
              }
              onClick={() => {
                setCourseClass("shs");
                dispatch({ type: "COURSE", payload: null! });
              }}
            >
              SHS Classes
            </Link>
          </Nav>
        </Container>
      </Navbar>{" "}
    </>
  );
};

export default HomeNav;
