import { ClassResponse, Links, Meta } from "../services/api.responses";

interface Action<T> {
  type: T;
}

export interface StateType {
  courses: { coursesData: ClassResponse[]; links: Links; meta: Meta };
  course: ClassResponse;
  loader: boolean;
  paymentLoader: boolean;
  searchedCourse: string;
  courseClass: string;
}

export const initialState: StateType = {
  courses: null!,
  course: null!,
  loader: false,
  paymentLoader: false,
  courseClass: "public",
  searchedCourse: null!,
};

export interface StateAction
  extends Action<
    "COURSES" | "COURSE" | "BOOKS" | "LOADER" | "SEARCH" | "COURSE_CLASS"
  > {
  payload: {
    courses: { coursesData: ClassResponse[]; links: Links; meta: Meta };
    course: ClassResponse;
    loader: boolean;
    paymentLoader: boolean;
    courseClass: string;
    searchedCourse: string;
  };
}

export const reducer = (
  state: StateType,
  action: any | StateAction
): StateType => {
  switch (action.type) {
    case "COURSES":
      return { ...state, courses: action.payload };
    case "COURSE":
      return { ...state, course: action.payload };
    case "LOADER":
      return { ...state, loader: action.payload };
    case "SEARCH":
      return { ...state, searchedCourse: action.payload };
    case "COURSE_CLASS":
      return { ...state, courseClass: action.payload };
    case "PAYMENT_LOADER":
      return { ...state, paymentLoader: action.payload };
    default:
      return state;
  }
};
