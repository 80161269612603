import React, { useContext, useEffect, useState } from "react";
import { Card, Form, Image, Spinner } from "react-bootstrap";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { GetClass, Pay } from "../services/api.service";
import StateContext from "../StateContext";
import DispatchContext from "../DispatchContext";
import {Price} from "../services/api.responses";

const PayCourse = () => {
  const dispatch = useContext(DispatchContext);
  const { course, loader, paymentLoader } = useContext(StateContext);
  const { id } = useParams();
  const [payment, setPayment] = useState<string>();
  const [quantity, setQuantity] = useState<number>();
  const [currency, setCurrency] = useState<string>();
  const [studentId, setStudentId] = useState<string>();
  const [promo, setPromo] = useState<string>();
  const [monthAmount, setMonthAmount] = useState<number>();
  const [yearAmount, setYearAmount] = useState<number>();
  const [yearsAmount, setYearsAmount] = useState<number>();

  useEffect(() => {
    GetClass(dispatch, id!);
    setStudentId(getEcID()!)
    setQuantity(1)
    setPayment('month')
    setCurrency('')
    setMonthAmount(0)
    // setCurrencyChange(currency)
    // getCurrencyPrices(currency)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const submit = (e: any) => {
    e.preventDefault();
    // getCurrencyPrices(currency)
    const data = {
      student_id: studentId!,
      class_id: course?.id,
      promo_code: payment === "promo" ? promo! : "",
      duration: payment !== "promo" ? getTheQuantity()! : "",
      currency: currency,
    };

    Pay(data, dispatch);
  };

  function getEcID() {
    const params = new URLSearchParams(window.location.search);
    // getCurrencyPrices(currency)
    return params.get('ecampus_id');
  }

  function getCurrencyPrices(currency : any) {
    let theprice;
    if(course){
      if(course.price_list != null){
        course.price_list?.forEach(function (price : Price){
          if(price.currency == currency){
            theprice = price
            setMonthAmount(price.month)
            setYearAmount(price.year)
            setYearsAmount(price.years)
          }
        });
      }else {
        setMonthAmount(course.subscription_amount_month)
        setYearAmount(course.subscription_amount_year)
        setYearsAmount(course.subscription_amount_years)

        theprice = {
          currency : currency,
          month : monthAmount,
          year : yearAmount,
          years : yearsAmount
        }
      }
    }
    return theprice
  }

  function getCurrencyPrice(thecurrency: string | undefined, frequency: string) {
    if (thecurrency == undefined){
      return ;
    }
    let amount : any = 0
    let price = getCurrencyPrices(thecurrency)

    if(price?.currency == thecurrency){
      if (frequency == 'month')
        amount = price?.month
      if (frequency == 'year')
        amount = price?.year
      if (frequency == 'years')
        amount = price?.years
    }
    return amount
  }

  function setCurrencyChange(curr: string) {
    // console.log('-------');
    setCurrency(curr)
    getCurrencyPrices(curr)
    return curr
  }

  function getTheQuantity() {
    if(payment === 'year') {
      return 12;
    }else if(payment === 'lifetime') {
      return 100;
    }else if(payment === 'month') {
      return quantity;
    }
    // getCurrencyPrices(thecurrency)
  }



  function getCur(){
    return currency ? currency : course?.subscription_currency
  }

  function getMonthAmt(){
    return monthAmount ? monthAmount : (course?.subscription_amount_month ?? 0 )
  }

  function getYearAmt(){
    return yearAmount ? yearAmount : (course?.subscription_amount_year ?? 0 )
  }

  function getYearsAmt(){
    return yearsAmount ? yearsAmount : (course?.subscription_amount_years ?? 0 )
  }

  return (
    <>
      <section id="PayCourse" className="pt-4">
        {loader ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>{" "}
          </div>
        ) : getMonthAmt() === 0 ? (
          <>
            <div className="d-flex flex-column flex-md-row gap-3">
              <div className="col-md-5 col-lg-4">
                <Image src={course?.logo_url} fluid className="border" />
              </div>
              <div>
                <h6>Description:</h6>
                <p>{course?.description}</p>

                <h6>Content Provider Name:</h6>
                <p>{course?.content_provider?.name}</p>

                <h6>Content Provider Description:</h6>
                <p>{course?.content_provider?.description}</p>

                <div className="d-flex justify-content-end">
                  <Link
                    to={`/class/${course?.id}`}
                    style={{ fontSize: "12px" }}
                    className="btn btn-primary px-5 py-3 text-nowrap gap-3 d-flex align-items-center"
                  >
                    View Course
                  </Link>{" "}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {
              // course?.subscription_currency ?
              // getCurrencyPrices(course?.subscription_currency) : getCurrencyPrices(currency)
            }
            <h2>Cart </h2>
            <Form className="row" onSubmit={submit}>
              <div className="col-lg-8 d-flex flex-column gap-4">
                <Card className="p-3">
                  <div className="row ">
                    <div className="col-sm-6 mb-3 d-flex flex-row align-items-center gap-4">
                      <img
                        src={course?.logo_url}
                        style={{ width: "50px" }}
                        className="border"
                        alt={course?.name}
                      />
                      <Form.Check
                        disabled={paymentLoader}
                        value="promo"
                        onChange={(e) => setPayment(e.target.value)}
                        checked={payment === "promo"}
                        type="radio"
                        id="promo"
                        label="Use Promo Code"
                      />
                    </div>
                    <div className="col-sm-4 d-flex justify-content-center align-items-center gap-3 ">
                      <Form.Control
                        type="text"
                        placeholder="Promo Code"
                        className="subject-search text-center"
                        aria-label="Search"
                        value={promo}
                        onChange={(e) => setPromo(e.target.value)}
                        disabled={payment !== "promo" || paymentLoader}
                        required
                      />
                    </div>
                  </div>
                </Card>
                <Card className="p-3">
                  <div className="row ">
                    <div className="col-md-6 d-flex flex-row align-items-center gap-4 mb-3">
                      <img
                        src={course?.logo_url}
                        style={{ width: "50px" }}
                        className="border"
                        alt={course?.name}
                      />
                      <Form.Check
                        disabled={paymentLoader}
                        value="month"
                        onChange={(e) => {
                          setPayment(e.target.value);
                        }}
                        checked={payment === "month"}
                        type="radio"
                        id="month"
                        label="Pay for a month"
                      />
                    </div>
                    <div className="col-6 col-md-2 ">
                      <Form.Control
                        type="number"
                        placeholder="Months"
                        min={1}
                        max={11}
                        className="subject-search text-center"
                        aria-label="Search"
                        // value={payment === "month" ? quantity : 1}
                        value={quantity}
                        onChange={(e) => setQuantity(Number(e.target.value))}
                        disabled={
                          payment === "month" ? false : true || paymentLoader
                        }
                        required
                      />
                    </div>
                    <div className="col-6 col-md-3">
                      <p className="h3 text-success">
                        {/*{currency}{" "}*/}
                        {/*{Intl.NumberFormat('en-US').format(monthAmount ?? 0)}*/}
                        {getCur()}{" "}
                        {Intl.NumberFormat('en-US').format(getMonthAmt())}
                        {/*{Intl.NumberFormat('en-US').format(course.subscription_amount_month ?? 0)}*/}
                      </p>
                    </div>
                  </div>
                </Card>

                <Card className="p-3">
                  <div className="row d-flex justify-content-between">
                    <div className="col-sm-6 d-flex flex-row align-items-center gap-4 mb-3">
                      <img
                        src={course?.logo_url}
                        style={{ width: "50px" }}
                        className="border"
                        alt={course?.name}
                      />
                      <Form.Check
                        disabled={paymentLoader}
                        value="year"
                        onChange={(e) => {
                          setPayment(e.target.value);
                        }}
                        checked={payment === "year"}
                        type="radio"
                        id="year"
                        label="Pay for a Year"
                      />
                    </div>
                    <div className="col-sm-4">
                      <p className="h3 text-success">
                        {" "}
                        {getCur()}{" "}
                        {Intl.NumberFormat('en-US').format(getYearAmt())}
                        {/*{Intl.NumberFormat('en-US').format(yearAmount ?? 0)}*/}
                      </p>
                    </div>
                  </div>
                </Card>

                {
                  getYearsAmt()!=0 ?
                    (
                      <Card className="p-3">
                        <div className="row d-flex justify-content-between">
                          <div className="col-sm-6 d-flex flex-row align-items-center gap-4 mb-3">
                            <img
                              src={course?.logo_url}
                              style={{ width: "50px" }}
                              className="border"
                              alt={course?.name}
                            />
                            <Form.Check
                              disabled={paymentLoader}
                              value="lifetime"
                              onChange={(e) => {
                                setPayment(e.target.value);
                              }}
                              checked={payment === "lifetime"}
                              type="radio"
                              id="lifetime"
                              label="Pay for a Lifetime"
                            />
                          </div>
                          <div className="col-sm-4">
                            <p className="h3 text-success">
                              {" "}
                              {getCur()}{" "}
                              {Intl.NumberFormat('en-US').format(getYearsAmt())}
                              {/*{Intl.NumberFormat('en-US').format(yearsAmount ?? 0)}*/}
                            </p>
                          </div>
                        </div>
                      </Card>
                    ) : ""
                }
              </div>

              <div className="col-lg-4 pt-4 pt-lg-0">
                <Card className="text-center">
                  <Card.Header
                    className="text-white d-flex justify-content-start align-items-center gap-3"
                    style={{ backgroundColor: "#ac2a0a " }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="13"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M20 4H4a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1ZM4 2a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3H4Zm2 5h2v2H6V7Zm5 0a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2h-6Zm-3 4H6v2h2v-2Zm2 1a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2h-6a1 1 0 0 1-1-1Zm-2 3H6v2h2v-2Zm2 1a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2h-6a1 1 0 0 1-1-1Z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Summary
                  </Card.Header>
                  <Card.Body className="d-flex justify-content-center align-items-start flex-column gap-4">
                    <Card.Title>
                      Payment for{" "}
                      <span className="text-capitalize">
                        {payment ?? "-"}
                      </span>
                    </Card.Title>


                    <Form.Group className="text-start col-12">
                      <Form.Label id="currency">Select Currency</Form.Label>
                      <Form.Select
                          placeholder="Currency"
                          id="currency"
                          onChange={(e) => setCurrencyChange(e.target.value)}
                          required
                          title="Select Currency"
                          // value={course?.subscription_currency ?? currency}
                          value={getCur()}
                      >
                        <option value={"USD"}>USD</option>
                        <option value={"GHS"}>GHS</option>
                        <option value={"NGN"}>NGN</option>
                        <option value={"XAF"}>XAF</option>
                        <option value={"XOF"}>XOF</option>
                        <option value={"ZAR"}>ZAR</option>
                        <option value={"KES"}>KES</option>
                      </Form.Select>

                    </Form.Group>


                    <Form.Group className="text-start col-12">
                      <Form.Label id="campus_id">eCampus ID</Form.Label>
                      <Form.Control
                          placeholder="eCampus ID (6 Characters)"
                          id="campus_id"
                          type="text"
                          onChange={(e) => setStudentId(e.target.value)}
                          required
                          value={studentId}
                          // pattern="(?=.*\d)(?=.*[a-z]).{6,}"
                          title="Must contain at least one  number and one letter, and at least 6"
                      />
                    </Form.Group>

                    <Card.Text className="d-flex flex-column justify-content-start align-items-start">
                      {payment === "month" && (
                        <p>
                          Total Amount:{" "}
                          <b className="h3 text-black fw-bold">
                            {" "}
                            {getCur()}{" "}
                            {quantity
                              ? Intl.NumberFormat('en-US').format(quantity * (getMonthAmt() ?? 0))
                              : 0}
                          </b>
                        </p>
                      )}
                      {payment === "year" && (
                        <p>
                          Price:{" "}
                          <b className="h3 text-black fw-bold">
                            {" "}
                            {getCur()}{" "}
                            {quantity
                              ? Intl.NumberFormat('en-US').format((getYearAmt() ?? 0))
                              : 0}
                          </b>
                        </p>
                      )}
                      {payment === "lifetime" && (
                        <p>
                          Price:{" "}
                          <b className="h3 text-black fw-bold">
                            {" "}
                            {getCur()}{" "}
                            {quantity
                              ? Intl.NumberFormat('en-US').format((getYearsAmt() ?? 0))
                              : 0}
                          </b>
                        </p>
                      )}

                      {/*{course?.price_list?.map((price: Price) => (*/}
                      {/*    <div*/}
                      {/*        className="col-12"*/}
                      {/*        key={course.id}*/}
                      {/*    >*/}
                      {/*      <small> {price.currency}</small>{" "}*/}
                      {/*      <span className="h6">{price.month}</span>*/}
                      {/*    </div>*/}
                      {/*))}*/}
                    </Card.Text>
                    <button
                      style={{ backgroundColor: "#ac2a0a" }}
                      disabled={paymentLoader}
                      className="btn mx-auto px-5 text-white d-flex align-items-center justify-content-center gap-3"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="13"
                        viewBox="0 0 432 432"
                      >
                        <path
                          fill="currentColor"
                          d="M128 344q18 0 30.5 12.5t12.5 30t-12.5 30T128 429t-30-12.5t-12-30t12-30t30-12.5zM0 3h70l20 42h315q9 0 15.5 6.5T427 67q0 5-3 10l-76 138q-12 22-38 22H151l-19 35v3q0 5 5 5h247v43H128q-18 0-30.5-12.5T85 280q0-11 6-20l28-53L43 45H0V3zm341.5 341q17.5 0 30 12.5t12.5 30t-12.5 30t-30 12.5t-30-12.5t-12.5-30t12.5-30t30-12.5z"
                        />
                      </svg>
                      Purchase
                      {paymentLoader && <Spinner animation="border" />}{" "}
                    </button>
                  </Card.Body>
                </Card>
              </div>
            </Form>
          </>
        )}
      </section>
    </>
  );
};

export default PayCourse;
