import {ClassResponse, Price} from "../services/api.responses";
import { Link } from "react-router-dom";
import {forEach} from "react-bootstrap/ElementChildren";
import React from "react";

const Course = ({ course }: { course: ClassResponse }) => {
  return (
    <>
      <Link
        to={`/class/${course.id}`}
        className="card p-2 border-0* text-decoration-none text-dark"
        style={{ height: "18em" }}
      >
        <div
          className="rounded* border mx-auto"
          style={{
            backgroundImage: `url(${course.logo_url})`,
            height: "100%",
            maxHeight: "160px",
            width: "100%",
            maxWidth: "160px",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
        <div className="card-body d-flex justify-content-start align-items-start flex-column gap-2">
          <div className=" col-10 w-100* text-truncate">
            <h6
              className="card-title fw-bolder text-truncate"
              style={{ fontSize: "13.5px" }}
            >
              {course.name}
            </h6>
            <small
              className="text-secondary text-truncate col-10*"
              style={{ fontSize: "13.5px" }}
            >
              {course.content_provider.name}
            </small>{" "}
          </div>

          <span className="badge* rounded-pill* text-bg-success* px-4* py-2* w-100">
                {/*{course.price_list?.map((price: Price) => (*/}
                {/*    <div*/}
                {/*        className="col-12"*/}
                {/*        key={course.id}*/}
                {/*    >*/}
                {/*        <small> {price.currency}</small>{" "}*/}
                {/*        <span className="h6">{price.month}</span>*/}
                {/*    </div>*/}
                {/*))}*/}
            <small> {course.subscription_currency}</small>{" "}
            <span className="h6">{course.subscription_amount_month}</span>
            <div className="d-flex justify-content-between pt-2">
              <small className="d-flex gap-2 align-items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-translate"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286H4.545zm1.634-.736L5.5 3.956h-.049l-.679 2.022H6.18z" />
                  <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm7.138 9.995c.193.301.402.583.63.846-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6.066 6.066 0 0 1-.415-.492 1.988 1.988 0 0 1-.94.31z" />
                </svg>
                {course?.language}
              </small>
              <small
                className="d-flex gap-2 align-items-center"
                style={{ color: "#ac2a0a" }}
              >
                {course?.category?.name}
              </small>
            </div>
          </span>
          {/* <Link
            to={`/class/${course.id}`}
            style={{ fontSize: "12px" }}
            className="btn btn-primary px-5 text-nowrap gap-3 d-flex align-items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="13"
              viewBox="0 0 432 432"
            >
              <path
                fill="currentColor"
                d="M384 3q18 0 30.5 12.5T427 45v235q0 18-12.5 30.5T384 323h-85v106l-86-42l-85 42V323H43q-18 0-30.5-12.5T0 280V45q0-17 12.5-29.5T43 3h341zm0 277v-43H43v43h341zm0-107V45H43v128h341z"
              />
            </svg>
            PAY
          </Link> */}
        </div>
      </Link>
    </>
  );
};

export default Course;
