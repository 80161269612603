import React, { useContext, useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import StateContext from "../StateContext";
import DispatchContext from "../DispatchContext";
import header_bg from "../img/page-bg.jpg";

const Header = () => {
  const dispatch = useContext(DispatchContext);
  const { course, courseClass } = useContext(StateContext);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    dispatch({ type: "SEARCH", payload: search });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <>
      <div
        className="ms-hero-img-mountain"
        style={{
          background: `linear-gradient(
      163deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ), url(${header_bg})`,
          boxShadow: "1px 1px 30px 0px rgba(0, 0, 0, 0.25)",
          // backgroundSize: "cover",
          // backgroundRepeat: "no-repeat",
          // backgroundPosition: "center center",
        }}
      >
        <Container>
          <div className="text-center text-white pt-5 pb-4 pb-lg-2">
            {course ? (
              <div className="col-md-8 mx-auto">
                <h3>
                  <span className="fw-normal">Subscription Payments for</span>
                  <b> {course?.name}</b>
                </h3>
                <p>
                  {course?.subscription_amount_month !== 0 &&
                    course?.description}
                </p>
              </div>
            ) : (
              <div>
                <div className="position-relative col-md-10 col-lg-6 mx-auto text-center d-flex flex-column gap-4">
                  <Form.Control
                    type="search"
                    placeholder="Search for Class"
                    className="subject-search text-center"
                    aria-label="Search"
                    onChange={(e) => setSearch(e.target.value)}
                  />

                  <h1 className="text-capitalize">
                    {!courseClass
                      ? "Paid"
                      : courseClass === "public"
                      ? "Other"
                      : courseClass}{" "}
                    Classes
                  </h1>
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
