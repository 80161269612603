import React, { useContext, useEffect } from "react";
import DispatchContext from "../DispatchContext";
import { GetClasses } from "../services/api.service";
import { useParams } from "react-router-dom";

const Classes = () => {
  const { class_id } = useParams();
  const dispatch = useContext(DispatchContext);

  useEffect(() => {
    GetClasses(dispatch, class_id!); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [class_id]);

  return (
    <>
      <div className="d-flex flex-wrap flex-md-nowrap d-md-none justify-content-center gap-md-3">
        <h6
          className="text-nowrap"
          style={
            class_id === "content"
              ? {
                  cursor: "pointer",
                  padding: "10px 12px",
                  background: "rgba(13,12,34,0.05)",
                  fontWeight: 500,
                  color: "#0d0c22",
                  borderRadius: "8px",
                }
              : {
                  cursor: "pointer",
                  color: "#6e6d7a",
                  padding: "10px 12px",
                }
          }
        >
          Content Providers
        </h6>
        <h6
          className="text-nowrap"
          style={
            class_id === "public"
              ? {
                  cursor: "pointer",
                  padding: "10px 12px",
                  background: "rgba(13,12,34,0.05)",
                  fontWeight: 500,
                  color: "#0d0c22",
                  borderRadius: "8px",
                }
              : {
                  cursor: "pointer",
                  color: "#6e6d7a",
                  padding: "10px 12px",
                }
          }
        >
          Public Classes
        </h6>
        <h6
          className="text-nowrap"
          style={
            class_id === "paid"
              ? {
                  cursor: "pointer",
                  padding: "10px 12px",
                  background: "rgba(13,12,34,0.05)",
                  fontWeight: 500,
                  color: "#0d0c22",
                  borderRadius: "8px",
                }
              : {
                  cursor: "pointer",
                  color: "#6e6d7a",
                  padding: "10px 12px",
                }
          }
        >
          Paid Classes
        </h6>
        <h6
          className="text-nowrap"
          style={
            class_id === "jhs"
              ? {
                  cursor: "pointer",
                  padding: "10px 12px",
                  background: "rgba(13,12,34,0.05)",
                  fontWeight: 500,
                  color: "#0d0c22",
                  borderRadius: "8px",
                }
              : {
                  cursor: "pointer",
                  color: "#6e6d7a",
                  padding: "10px 12px",
                }
          }
        >
          JHS Classes
        </h6>
        <h6
          className="text-nowrap"
          style={
            class_id === "shs"
              ? {
                  cursor: "pointer",
                  padding: "10px 12px",
                  background: "rgba(13,12,34,0.05)",
                  fontWeight: 500,
                  color: "#0d0c22",
                  borderRadius: "8px",
                }
              : {
                  cursor: "pointer",
                  color: "#6e6d7a",
                  padding: "10px 12px",
                }
          }
        >
          SHS Classes
        </h6>
      </div>
    </>
  );
};

export default Classes;
