import React, { useContext, useEffect, useState } from "react";
import { ClassResponse, Links } from "../services/api.responses";
import { Nav, Pagination, Spinner } from "react-bootstrap";
import StateContext from "../StateContext";
import Course from "./Course";
import DispatchContext from "../DispatchContext";
import { GetPaginatedPage } from "../services/api.service";

const Courses = () => {
  const { loader, courses, searchedCourse } = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const [classCourses, setClassCourses] = useState<ClassResponse[]>([]);

  useEffect(() => {
    let results: ClassResponse[] = [];

    if (searchedCourse && searchedCourse !== "") {
      // eslint-disable-next-line array-callback-return
      results = courses?.coursesData?.filter((item) => {
        if (
          JSON.stringify(item)
            .toLowerCase()
            .includes(searchedCourse.toLowerCase())
        ) {
          return item;
        }
      });
    } else {
      results = courses?.coursesData;
    }

    setClassCourses(results);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedCourse]);

  useEffect(() => {
    setClassCourses(courses?.coursesData);
  }, [courses?.coursesData]);

  return (
    <>
      <section id="Courses">
        {loader ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>{" "}
          </div>
        ) : (
          <div className="row">
            {classCourses && classCourses?.length > 0 ? (
              <>
                {classCourses.map((course: ClassResponse) => (
                  <div
                    className="col-7 col-sm-6 col-md-4 col-lg-3 col-xl-2 pb-5 mx-auto* course-container"
                    key={course.id}
                  >
                    <Course course={course} />
                  </div>
                ))}
                <Nav
                  aria-label="..."
                  className="mx-auto d-flex justify-content-center"
                >
                  <Pagination>
                    {courses?.meta?.links.map((link: Links) => (
                      <>
                        {!Number.isNaN(Number(link.label)) && (
                          <Pagination.Item
                            key={link.label}
                            active={link.active}
                            className="rounded-0"
                            onClick={() => GetPaginatedPage(dispatch, link.url)}
                          >
                            {link.label}{" "}
                          </Pagination.Item>
                        )}
                      </>
                    ))}
                  </Pagination>{" "}
                </Nav>
              </>
            ) : (
              <div className="text-center">
                <h1>No course available</h1>
              </div>
            )}
          </div>
        )}
      </section>
    </>
  );
};

export default Courses;
