import axios from "axios";
import Swal from "sweetalert2";

export const baseURL = process.env.REACT_APP_API_ENDPOINT;
export const webApp = process.env.REACT_APP_WEB_APP_ENDPOINT ?? "https://web.ecampus.camp"

export const api = axios.create({
  baseURL,
});

export const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const GetClasses = async (
  dispatch: ({ type, payload }: { type: string; payload: any }) => void,
  classType: string
) => {
  dispatch({ type: "COURSE_CLASS", payload: classType });
  dispatch({ type: "COURSES", payload: null! });
  dispatch({ type: "LOADER", payload: true });

  return await api
    .get(`public/classes/${classType ?? "paid"}`)
    .then((res) => {
      const { data: coursesData, links, meta } = res.data;

      dispatch({ type: "COURSES", payload: { coursesData, links, meta } });
      dispatch({ type: "LOADER", payload: false });
    })
    .catch((error) => {
      dispatch({ type: "LOADER", payload: true });
    });
};

export const GetPaginatedPage = (
  dispatch: ({ type, payload }: { type: string; payload: any }) => void,
  url: string
) => {
  dispatch({ type: "LOADER", payload: true });
  api
    .get(url)
    .then((res) => {
      const { data: coursesData, links, meta } = res.data;
      dispatch({ type: "COURSES", payload: { coursesData, links, meta } });
      dispatch({ type: "LOADER", payload: false });
    })
    .catch((error) => {
      dispatch({ type: "LOADER", payload: true });
    });
};

export const Pay = (
  data: {
    student_id: string | number;
    class_id: number | string;
    promo_code?: string | number;
    duration?: number | string;
  },
  dispatch: ({ type, payload }: { type: string; payload: any }) => void
) => {
  dispatch({ type: "PAYMENT_LOADER", payload: true });

  api
    // .post("pay", { ...data })
    .post("pay/link", { ...data })
    .then((res) => {
      const { message, subscription, payment_link } = res.data;
      if (subscription) {
        Toast.fire({
          text: `Payment processing!`,
          icon: "success",
        });
          if (payment_link){
              window.location.href = payment_link;
          }
          else{
              // window.location.href = `${webApp}/class/${data?.class_id}`;
              window.location.href = `${webApp}/classes/${data?.class_id}/courses`;
          }
      } else {
        Toast.fire({
          text: `Payment Failed! ${message && message}`,
          icon: "error",
        });
      }

      dispatch({ type: "PAYMENT_LOADER", payload: false });
    })
    .catch((error) => {
      dispatch({ type: "PAYMENT_LOADER", payload: false });
    });
};

export const GetClass = async (
  dispatch: ({ type, payload }: { type: string; payload: any }) => void,
  class_id: string | number
) => {
  dispatch({ type: "LOADER", payload: true });

  return await api

    .get(`public/classes/${class_id}/courses`)
    .then((res) => {
      const { content_class } = res.data;

      dispatch({ type: "COURSE", payload: content_class });
      dispatch({ type: "LOADER", payload: false });
    })
    .catch((error) => {
      dispatch({ type: "LOADER", payload: true });
    });
};
