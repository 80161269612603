import React from "react";

const Footer = () => {
  return (
    <>
      {" "}
      <footer id="Footer" className="text-center bg-light mt-5 py-2 footer">
        <div className="ms-hero-img-beach ms-hero-bg-info ms-bg-fixed pt-2 pb-2">
          <div className="container">
            <div className="text-center mb-4">
              <h1
                className="text-center color-white wow fadeInUp animation-delay-4"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                Download Now
              </h1>
            </div>
            <div className="text-center ms-margin d-flex flex-column flex-sm-row justify-content-center align-items-center gap-4">
              <a
                href="http://bit.ly/ecampus-android"
                target="_blank"
                className="btn btn-primary btn-raised btn-app wow zoomInUp animation-delay-3"
                style={{ visibility: "visible", animationName: "zoomInUp" }}
                rel="noreferrer"
              >
                <div className="btn-container d-flex flex-column flex-sm-row gap-4">
                  <i className="fa fa-android fa-3x"></i>
                  <div>
                    <span>Available in </span>
                    <br />
                    <strong>Play Store</strong>{" "}
                  </div>
                </div>
              </a>
              <a
                href="http://bit.ly/ecampus-ios"
                target="_blank"
                className="btn btn-primary btn-raised btn-app wow zoomInUp animation-delay-2"
                style={{ visibility: "visible", animationName: "zoomInUp" }}
                rel="noreferrer"
              >
                <div className="btn-container d-flex flex-column flex-sm-row gap-4">
                  <i className="fa fa-apple fa-3x"></i>{" "}
                  <div>
                    <span>Available in </span>
                    <br />
                    <strong>App Store</strong>{" "}
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <p className="pt-3">Copyright @ eCampus {new Date().getFullYear()}</p>
      </footer>{" "}
    </>
  );
};

export default Footer;
