import React, { useEffect, useReducer } from "react";
import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import PayCourse from "./pages/PayCourse";
import Nav from "./components/Nav";
import Header from "./components/Header";
import { Container } from "react-bootstrap";
import Footer from "./components/Footer";
import StateContext from "./StateContext";
import DispatchContext from "./DispatchContext";
import { initialState, reducer } from "./state/reducer";

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const location = useLocation();

  useEffect(() => {
    location.pathname === "/" && localStorage.removeItem("course");
  }, [location]);

  return (
    <>
      <StateContext.Provider value={state}>
        <DispatchContext.Provider value={dispatch}>
          <Nav />
          <Header />

          <Container className="pb-5">
            <Routes>
              <Route path="/:class_id" element={<Home />} />
              <Route path="/class/:id/" element={<PayCourse />} />
              <Route path="*" element={<Home />} />
            </Routes>
          </Container>

          <Footer />
        </DispatchContext.Provider>
      </StateContext.Provider>
    </>
  );
}

export default App;
